import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutService from "../../templates/LayoutService";
import {
  blogHeader,
  blogPostContainer,
  postContentBlock,
} from "../../styles/components/blog.module.scss";
import { pageContentMedia } from "../../styles/components/blocks.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { imgBox } from "../../styles/components/boxes.module.scss";
import "../../styles/components/content.module.scss";
import ServicesList from "../../components/blocks/ServicesList";
import { Link } from "gatsby";

const BlogPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>
          What Happened To Beer Can Island? | Tampa Bay Boating Adventures
        </title>
      </Helmet>
      <main className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={blogHeader}>
              <div className={imgBox}>
                <StaticImage
                  alt="captain crew bar island "
                  src="../../images/blog/captain-crew-bar-island.jpg"
                />
              </div>
            </div>
            <div className={blogPostContainer}>
              <div className="row center">
                <div className="col">
                  <h1>What Happened to Beer Can Island?</h1>
                  <p>March 09, 2024</p>
                </div>
              </div>
              <div className="row">
                <div className="col-xxs-12">
                  <div className={postContentBlock}>
                    <p>
                      One local hot-spot well known in the boating community
                      that is hitting the news over the past few years, has gone
                      by a few names. You might have originally heard it called 
                      <a style={{marginLeft: "5px"}} href="https://maps.app.goo.gl/R6T4csW7YSNoztGW8">
                        Beer Can Island
                      </a>{" "}
                      if you’ve been around long enough… Or{" "}
                      <a href="https://www.axios.com/local/tampa-bay/2024/02/15/beer-can-island-for-sale-pine-key-real-estate">
                        Pine Key
                      </a>{" "}
                      if you’re newer to the area. Or even yet, depending on how
                      ingrained in the house music community you might be, you
                      may have heard of the property referred to as High Tide
                      Island.
                    </p>
                    <div className={pageContentMedia}>
                      <div className={imgBox}>
                        <StaticImage
                          alt="beer can island map distance"
                          src="https://tampabayboatingadventures.com/beer-can-island-to-downtown-tampa-map.jpg"
                        />
                      </div>
                    </div>

                    <p>
                      Whatever you refer to the shrinking island as, there
                      recently has been a lot of buzz surrounding this island.
                      <strong>
                        {" "}
                        Beer Can Island is currently closed to the public and is
                        for sale for 14.2 million dollars
                      </strong>
                      . The popular party spot has been experiencing some
                      massive changes. With all these changes, and the island
                      under threat, many have been wondering what the future is
                      for this area. Let’s take a closer look:
                    </p>

                    <h2>Tampa’s Premier Party Island Is For Sale… Again</h2>
                    <p>
                      The notorious island located seven miles south of Davis
                      Island in Tampa, FL is for sale… but this time at a much
                      higher price tag! The island itself sits between Apollo
                      Beach and MacDill Airforce Base and has for years been a
                      popular stop off for boats coasting around the area.
                      Recently, it hit the market for an unbelievable price tag
                      of $14.2 million! Many were shocked by the massive costs,
                      but there is even more to this story than meets the eye.
                    </p>

                    <div className={pageContentMedia}>
                      <div className={imgBox}>
                        <StaticImage
                          alt="beer can island map distance"
                          src="../../images/blog/volleyball-on-beach-at-beer-can-island.jpg"
                        />
                      </div>
                    </div>

                    <p>
                      The island itself is nine acres in total, but it also has
                      60 acres of land submerged underwater. What makes it stand
                      out from a lot of other islands in the area is that it is
                      only of the few that are privately owned, having been
                      purchased in 2017 by a group of friends.
                      <strong> Back then</strong>,
                      <a href="https://www.fox13news.com/news/beer-can-island-tampa-goes-up-for-sale-closes-to-the-public-all-good-things-must-come-to-an-end">
                        {" "}
                        they paid only $64,000 for the island
                      </a>{" "}
                      <strong>
                        – a tiny price tag in comparison to the asking price
                        this year.
                      </strong>
                    </p>
                    <div className={pageContentMedia}>
                      <div className={imgBox}>
                        <StaticImage
                          alt="beer can island map distance"
                          src="../../images/blog/students-dancing-water-pine-key-tampa.jpg"
                        />
                      </div>
                    </div>
                    <p>
                      To be fair to the friends Cole Weaver, Johnny Gadd, James
                      West and Russell Loomis, they have significantly upgraded
                      the island since they first bought it. Over the years they
                      have added amenities that have made it the perfect party
                      destination. This includes water slides, tiki bars and
                      bathrooms for any visitors. Many visitors purchased
                      membership access to these facilities if they were
                      regulars to the Tampa Bay area. <br />
                      <br />
                      However, despite these improvements to Beer Can Island, it
                      has faced some significant challenges.
                    </p>
                    <h2>
                      Pine Key (Beer Can Island ) Faces Legal, Environmental, &
                      Zoning Challenges
                    </h2>
                    <p>
                      Firstly, there were the legal challenges, with many
                      Hillsborough County residents raising concerns that the
                      island was not safe and breaking a number of codes. Part
                      of these violations are linked to the fact that{" "}
                      <a href="https://hillsboroughcounty.legistar1.com/hillsboroughcounty/meetings/2023/3/1243_M_BOCC_Land_Use_23-03-07_Results.pdf">
                        the island is not zoned
                      </a>{" "}
                      <strong>
                        so should not be developed until this issue is
                        rectified.
                      </strong>
                      <br /> <br />
                      Instead,{" "}
                      <strong>
                        many suggested it become a nature preserve
                      </strong>{" "}
                      to help look after local wildlife – ruining any plans for
                      potential future party-goers.
                      <br />
                      <br /> But that isn’t the only issue the island has faced
                      in recent years. Florida has been hit with a number of
                      storms and hurricanes. The last hurricane – Hurricane
                      Idalia did some lasting damage to the island with many
                      reporting a lot of it was left
                      <a href="https://www.tampabay.com/weather/2023/09/01/hurricane-idalia-beer-can-island-tampa-bay/">
                        submerged under five feet of water.
                      </a>{" "}
                      <strong>
                        The land itself has been decreasing over recent years
                        too – with it reducing at least 14 acres between 2007
                        and 2023.
                      </strong>{" "}
                      <br />
                      Experts believe that this erosion is due to constant waves
                      which are exacerbated by the passing of large ships
                      through the nearby shipping channels. <br /> <br /> Rumor
                      has it that the county has paused their{" "}
                      <a href="https://tampabayboatingadventures.com/blog/the-history-of-beer-can-island-aka-pine-key/#:~:text=Beer%20Can%20Island%20spans%20over,rid%20of%20the%20excess%20sediment.">
                        {" "}
                        deliverance of sediment to the island,
                      </a>{" "}
                      which initially formed the backbone of the island we know
                      today, due to the ongoing zoning disputes with
                      Hillsborough County. <br /> <br /> This has led to the
                      current owners trying to come up with some creative ideas
                      on how to save it.
                    </p>
                    <h2>The (Never-Ending) Battle to Save Beer Can Island</h2>
                    <p>
                      To try and raise money to help save the dwindling island,
                      the owners tried to raise awareness and asked for
                      <a href="https://www.gofundme.com/f/save-our-shore-pine-key-project-preservation">
                        donations via the GoFundMe platform.
                      </a>{" "}
                      <strong>
                        The goal of the GoFundMe was to raise around half a
                        million dollars to try and protect the existing land
                      </strong>{" "}
                      and expand it to recapture some of the lost land.
                    </p>
                    <div className={pageContentMedia}>
                      <div className={imgBox} style={{ margin: "0 auto" }}>
                        <StaticImage
                          alt="beer can island map distance"
                          src="../../images/blog/pine-key-go-fund-me.jpg"
                        />
                      </div>
                    </div>
                    <p>
                      The owners contacted engineers to construct plans on how
                      to save the island but the campaign itself has so far not
                      raised the money needed.
                      <br /> <br /> While some of the local community is on
                      board with this, and the campaign has garnered media
                      attention, it has only managed to raise just over $1,000
                      for their goal. The final amount is a long way off. With
                      further support, Beer Can Island could still be saved, but
                      it would take a lot of money and a lot of effort to reach
                      this position if local funding is to be the solution.{" "}
                      <br /> <br />
                      The owners want to protect this picturesque area so that
                      more people can enjoy the sandy beach in this idyllic
                      setting – just like they have in years gone by.
                    </p>
                    <h2>Beer Can Island - Potential Nature Preserve</h2>
                    <p>
                      While some people love the island for its parties and
                      excellent location, it is also an important place for
                      nature.{" "}
                      <strong>
                        The island itself has a unique ecosystem where a number
                        of rare plants and animals reside.
                      </strong>{" "}
                      The beaches are a great spot for sea turtles to nest at
                      certain points within the year, and many birds also
                      frequent the island. <br /> <br/> By taking action to protect Beer Can
                      Island, we're not just saving a piece of land - we're
                      making a commitment to environmental conservation. It's a
                      chance to show that we value our natural resources and are
                      willing to fight to preserve them. But does preserving the
                      nature conflict with the party image that the island has
                      established? There might only be space for one or the
                      other.
                    </p>
                    <h2>A Legacy at Risk: What’s Next for Beer Can Island?</h2>
                    <p>
                      Beer Can Island is a firm favourite in the eyes of Tampa
                      Bay party-goers and explorers alike. It is a symbol of
                      exploration, freedom and a chilled-out lifestyle that many
                      love and appreciate. There is still some hope that a large
                      donor or big campaign push will raise the funds needed.
                      The island is currently for sale and is aimed at
                      <strong>
                        “corporations and international investors seeking a
                        unique retreat,”
                      </strong>{" "}
                      however the 14.2 million dollar price tag may be a bit
                      ambitious. With the island being within the hearts of so
                      many, its spirit will live on, regardless of its future.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ServicesList />
          </div>
        </div>
      </main>
    </LayoutService>
  );
};

export default BlogPage;
